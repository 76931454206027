if ($('body.page_1').length > 0) {
    addUtmParaFunc()
}

function addUtmParaFunc() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('utm_source') && urlParams.has('utm_medium') && urlParams.has('utm_campaign')) {
        const utmSource   = urlParams.get('utm_source');
        const utmMedium   = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
    
        Array.from(document.getElementsByTagName('a')).forEach((el) => {
            if (el.getAttribute('href')?.startsWith('/')) {
                el.href = el.getAttribute('href') + '?utm_source=' + utmSource + '&utm_medium=' + utmMedium + '&utm_campaign=' + utmCampaign;
            }
        });
    }
}

function addUtmParaFormFunc(event) {

    let btn = event.currentTarget.myParam;

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let utmSource = urlParams.get('utm_source');
    let utmMedium = urlParams.get('utm_medium');
    let utmCampaign = urlParams.get('utm_campaign');

    if ( urlParams.has('utm_source') ) {
        sessionStorage.setItem("utm_source", utmSource);
    }

    if ( urlParams.has('utm_medium') ) {
        sessionStorage.setItem("utm_medium", utmMedium);
    }

    if ( urlParams.has('utm_campaign')  ) {
        sessionStorage.setItem("utm_campaign", utmCampaign);
    }
}