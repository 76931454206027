export default class ValidationTarget {

    isValid = false;
    value;
    element;

    constructor(obj) {
        this.value = obj.value
        this.element = obj.element
    }

    handleValid() {
        this.isValid = true;

        if (this.element.attr('name') === 'radio') {
            $('.form-group-radio', '.bob-frame').removeClass('is-invalid');
            return
        } else if (this.element.attr('name') === 'insuranceName') {
            $('select[name="insuranceName"]').removeClass('is-invalid');
            return
        } else if (this.element.attr('name') === 'fileupload') {
            document.getElementById('custom-file-upload').classList.remove('is-invalid');
            return
        }

        this.element.removeClass('is-invalid');
    }

    handleInvalid() {
        this.isValid = false

        if (this.element.attr('name') === 'radio') {
            $('.form-group-radio', '.bob-frame').addClass('is-invalid');
            return
        } else if (this.element.attr('name') === 'insuranceName') {
            $('select[name="insuranceName"]').addClass('is-invalid');
            return
        } else if (this.element.attr('name') === 'fileupload') {
            document.getElementById('custom-file-upload').classList.add('is-invalid');
            return
        }

        this.element.addClass('is-invalid');
    }

    hasFailedToValidate() {
        if (this.element.attr('name') === 'referrer') {
            return this.value === "0"
        }

        if (this.element.attr('name') === 'fileupload') {
            if (this.value === '') {
                return true
            }

            const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
            const fileInput = this.element[0]
            const file = fileInput.files[0]

            if (file) {
                // Check file type
                if (!allowedTypes.includes(file.type)) {
                    return true
                }

                // Check if file size > 5MB
                const maxSize = 5 * 1024 * 1024;
                if (file.size > maxSize) {
                    return true
                }
            }

            return false
        }

        if (this.element.attr('name') === 'phone') {
            return this.value.trim() === '' || _.validatePhone(this.value.trim()) === false
        }

        if (this.element.attr('name') === 'plz') {
            return this.value.trim() === '' || this.value.trim().length < 4 || _.validateNumber(this.element, this.value.trim()) === false
        }

        if (this.element.attr('name') === 'email') {
            return this.value.trim() === '' || _.validateEmail(this.value.trim()) === false
        }

        if (this.element.attr('name') === 'emailconfirm') {
            return this.value.trim() === '' || _.validateEmail(this.value.trim()) === false || this.value.trim() !== $('input[name="email"]').val()
        }

        if (this.element.attr('name') === 'radio') {
            return $('input[type=radio]:checked', '.bob-frame').length === 0
        }

        if (this.element.attr('name') === 'insuranceName') {
            return this.value.trim() === ''
        }

        if (this.element.attr('name') === 'kvnr') {
            return this.value.trim() === '' || this.value.trim().length !== 10
        }

        if (this.element.attr('name') === 'salutation') {
            return ['w', 'm', 'd'].includes(this.value) === false
        }

        if (this.element.attr('name') === 'title') {
            return ['', 'dr', 'profdr', 'profdrdr'].includes(this.value) === false
        }

        if (this.element.attr('name') === 'officename' || this.element.attr('name') === 'officeaddress' || this.element.attr('name') === 'officecity') {
            return this.value === ''
        }

        if (this.element.attr('name') === 'officezip') {
            return this.value.trim() === '' || this.value.trim().length < 4 || this.value.trim().length > 5 || _.validateNumber(this.element, this.value.trim()) === false
        }

        if (this.element.attr('type') === 'checkbox') {
            return $('input[type=checkbox]:checked', '.bob-frame').length === 0
        }

        else {
            return this.value.trim() === '' || this.value.trim().length > this.element.attr('maxlength')
        }
    }

}