import * as Globals from './globals_digas.js';

(function ($) {

    /**
     * wizard plugin 3
     * setData form to session storafe
     * @returns {jQuery}
     */
    $.fn.bobFormWizard3 = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;
        let event_namespace = '.bobWizard3';

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);

            // get form values
            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable submit button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

                // stop execution
                return;
            }

            sendData();
        };

        let sendData = function () {

            let data = prepareData();

            $('document').spinner('show');

            $.ajax({
                url: "/zen",
                type: "POST",
                data: data,
                cache: false,
                success: function () {

                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#s2-bob-wizard-success2'
                    });

                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', 'kontakt-form');

                    $('document').spinner('hide');

                    $('.bob-frame form' + settings.classForm).addClass('hidden').trigger("reset");

                    _.submitBtnDisabled(pre, false);

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                },
                error: function (err) {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);

                    console.error(err);

                    // enable button again
                    _.submitBtnDisabled(pre, false);
                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validateFormData = function (formData) {

            $('.form-group input', g.FRAME_FORM_SELECTOR).removeClass('is-invalid');

            let validItem1 = false;
            let validItem2 = false;
            let validItem3 = false;

            // jquery elements
            let $email = formData.values.email.element;
            let $firstname = formData.values.firstname.element;
            let $secondname = formData.values.secondname.element;

            // values
            let emailValue = formData.values.email.value;
            let firstnameValue = formData.values.firstname.value;
            let secondnameValue = formData.values.secondname.value;

            validItem1 = _.checkLength($firstname, firstnameValue, 1, 100);
            validItem2 = _.checkLength($secondname, secondnameValue, 1, 100);

            if (emailValue.trim() === "" || _.validateEmail(emailValue.trim()) === false) {
                $email.addClass('is-invalid');
            } else {
                $email.removeClass('is-invalid');
                validItem3 = true;
            }

            return validItem1 && validItem2 && validItem3;

        };

        let prepareData = function () {

            let formData = _.getFormData(g.FRAME_FORM_SELECTOR);

            let zData = {
                "name": formData.data.firstname + " " + formData.data.secondname,
                "email": formData.data.email,
                "description": formData.data.description,
                "subject": formData.data.subject,
                "recipient": formData.data.recipient
            }

            return zData

        };

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));