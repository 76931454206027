(function ($) {


    /**
     * menuScrollAnimations plugin
     * this handels scroll and resize events for the menu
     * this provides the navbar-animate class to the menu
     * navbar-animate acts different for mobile than for desktop
     */
    $.fn.menuScrollAnimations = function () {

        let lastScrollTop = 0;
        let scrollTop = 0;
        let $navbar = $('.navbar');

        /**
         * throttled_scrollAnimation function
         * based on the screensize the menu his hidden(mobile) or srunk(desktop
         * it is debounced to limit the number of functions calls on scroll. This is done to help performance.
         */
        let throttled_scrollAnimation = _.throttle(function () {

            let screenSize = window.innerWidth;

            /**
             * shrinkAnimation function
             * this shrinks the navbar when its scrolled past 30px
             * is only called in desktop
             */
            let shrinkAnimation = function () {

                $navbar.removeClass('navbar-animate');
                scrollTop = $(window).scrollTop();

                if (scrollTop >= 30) {
                    $navbar.addClass('navbar-animate');
                } else if (scrollTop < 30) {
                    $navbar.removeClass('navbar-animate');
                }
            }

            /**
             * hideAnimation function
             * this hides the navbar when its scrolled down
             * this shows the navbar when scrolled up
             * is only called in mobile
             */
            let hideAnimation = function () {

                // $navbar.removeClass('navbar-animate');
                let scrollToTop = $(window).scrollTop();
                
                if (scrollToTop > lastScrollTop && scrollToTop > 40) {
                    $navbar.addClass('navbar-animate');
                }

                if (scrollToTop < lastScrollTop -120 || scrollToTop === 0)  {
                    $navbar.removeClass('navbar-animate');
                }

                lastScrollTop = scrollToTop;
            }

            if (screenSize >= 768) {
                shrinkAnimation();
            } else if (screenSize < 768) {
                hideAnimation();
            }

        }, 300, {
            'leading': true
        })

        /**
         * window resize event listener
         * this is added so the correct animation is used, even when the browser window was resized
         */
        $(window).on('resize', function () {
            throttled_scrollAnimation();
        });

        /**
         * scroll event listener
         * this is added so when can show animations on the scroll event
         * depending on the screensie throttled_scrollAnimation will act in different ways
         */
        $(window).on('scroll', function () {
            throttled_scrollAnimation();
        });
    }
}(jQuery)); 
