/** 
 * Third-grade students = 150 words per minute (wpm)
 * Eighth grade students = 250 wpm
 * Average college student = 450 wpm
 * Average "high-level exec" = 575 wpm
 * Average college professor = 675 wpm
 * Speed readers = 1,500 wpm
 * World speed reading champion = 4,700 wpm
 * Average adult = 300 wpm
 */

function readingTime() {
    if (document.getElementsByClassName("blog-content-container").length) {
        const text = document.getElementsByClassName("blog-content-container")[0].innerText;
        const wpm = 225; 
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        console.log(text)
        console.log(words)
        console.log(time)
        document.getElementById("readingTime").innerText = time;
    }
}
setTimeout(() => {
    readingTime()
}, 500);