/**
 * callback of the IntersectionObserver
 * This is what happens when the individual entry is scrolled into view
 * to add an animated svg icon you simple need to wrap in in an html tag with the class .animate-icon
 * the svg needs to contain the actual animation in a style tag (CSS Animation in Keyshape)
 * to avoid doublicated IDs the Object IDs in the svg should be prefixed (export in Keyshape)
 * write motion parts as transforms needs to be selected (export in Keyshape)
 */
let options = {
    rootMargin: '0px',
    threshold: 1.0
}

const callback = (entries) => {

    entries.forEach(entry => {

        if (entry.isIntersecting) {

            let $entryTarget = $(entry.target);
            let $entryTargetChildren = $(entry.target).children();

            if ($entryTargetChildren.hasClass('animate-icon-show') || $entryTarget.hasClass('animate-icon-show')) {
                return
            }

            if (entry.target.classList.contains('type-screenshot')) {
                animateScreenshotSection($entryTarget);
            }

            if (entry.target.classList.contains('type-simple')) {
                animateSimpleImage($entryTarget);
            }
            
            if (entry.target.localName === 'div') {
                triggerKeyshpeAnimation($entryTargetChildren, $entryTarget);
            }

        }
    });
}

/**
 * animateScreenshotSection
 * is animating the Screenshot Section
 * here an image and its text are animated 
 * we differentiate the timing of the animation in css depending if its left or right
 */
let animateScreenshotSection = function ($entryTarget) {

    let $thisText = false;
    const $entryTargetTextContainer = $entryTarget.siblings(".screenshots-item-middle");

    $entryTarget.addClass('animate-icon-show');

    if ($entryTarget.hasClass('left')) {
        $thisText = $entryTargetTextContainer.children(".left");

    } else if ($entryTarget.hasClass('right')) {
        $thisText = $entryTargetTextContainer.children(".right");
    }

    if ($thisText) {
        $thisText.addClass('animate-icon-show');
    }

}

/**
 * animateSimpleImage
 */
 let animateSimpleImage = function ($entryTarget) {
    $entryTarget.addClass('animate-icon-show');
}

/**
 * triggerKeyshpeAnimation
 * is triggering the keyshape js animation 
 * this is used in the steps section or the devices animation
 */
let triggerKeyshpeAnimation = function ($entryTargetChildren, $entryTarget) {
  //  $entryTarget.css('height', $entryTarget.height());
    $entryTargetChildren.clone().appendTo($entryTarget).addClass('animate-icon-show');
    $entryTargetChildren.remove();
}

/**
 * 1]Create a new intersectionObserver object,
 * which will accept a callback function and options as parameters.
 */
let observer = new IntersectionObserver(callback, options);

/**
 *  2]Select all elements that have ".animate-icon" 
 * makes it possible to have multiple svg icons with the same behavior
 */
const animationItems = document.querySelectorAll('.animate-icon');
/**
 *  3]Loop through selected elements and add to the observer watch list.
 */
animationItems.forEach(item => {
    observer.observe(item)
})