import * as Globals from './globals_digas.js'
import ValidationTarget from './ValidationTarget.js';

(function ($) {

    /**
     * bobFormContactHubspot plugin
     * setData form to session storage
     * @returns {jQuery}
     */
    $.fn.bobFormContactHubspot = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;
        let event_namespace = '.bobFormContactHubspot';

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);

            // get form values
            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable submit button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

                // stop execution
                return;
            }

            sendData();
        };

        let sendData = function () {

            let data = prepareData();

            $('document').spinner('show');

            $.ajax({
                url: settings.endpoint,
                type: "POST",
                data: JSON.stringify(data),
                dataType: "json",
                cache: false,
                contentType: "application/json",

                success: function () {

                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#s2-bob-wizard-success2'
                    });

                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', 'kontakt-form');
                    _.eTrackerOrder(settings.etrackerId);

                    $('document').spinner('hide');
                    // $('.bob-frame form' + settings.classForm)[0].reset();
                    $('.bob-frame form' + settings.classForm).addClass('hidden').trigger("reset");

                    _.submitBtnDisabled(pre, false);

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                },
                error: function (err) {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);

                    console.error(err);

                    // enable button again
                    _.submitBtnDisabled(pre, false);
                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validateFormData = function (formData) {

            $('.form-group input', g.FRAME_FORM_SELECTOR).removeClass('is-invalid');

            let results = [];
            let validationTargets = [
                formData.values.firstname,
                formData.values.lastname,
                formData.values.email
            ];

            let $checkbox = formData.checkboxes.checkbox.element;
            let checkboxValue = formData.checkboxes.checkbox.status;

            validationTargets.forEach(function (item) {

                let that = new ValidationTarget(item)

                if (that.hasFailedToValidate()) {
                    that.handleInvalid();
                } else {
                    that.handleValid();
                }

                results.push(that.isValid);
            })

            if ( checkboxValue === false) {
                $checkbox.addClass('is-invalid')
            } else {
                $checkbox.removeClass('is-invalid')
                return results.every(el => el === true);
            }
        };

        let prepareData = function () {

            let data = _.getFormData(g.FRAME_FORM_SELECTOR);

            let utm_source = sessionStorage.getItem("utm_source");
            let utm_medium = sessionStorage.getItem("utm_medium");
            let utm_campaign = sessionStorage.getItem("utm_campaign");

            let values = data.data;

            let data_endpoint = {
                "properties": {
                    "email": values.email,
                    "firstname": values.firstname,
                    "lastname": values.lastname,
                    "phone": (values.phone !== "") ? values.phone : 'Tel. nicht angegeben',
                    "utm_source": (utm_source !== null) ? utm_source : 'No source',
                    "utm_medium": (utm_medium !== null) ? utm_medium : 'No medium',
                    "utm_campaign": (utm_campaign !== null) ? utm_campaign : 'No campaign'
                }
            }

            sessionStorage.removeItem("utm_source");
            sessionStorage.removeItem("utm_medium");
            sessionStorage.removeItem("utm_campaign");

            return data_endpoint
        };

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));