import * as Globals from './globals_digas.js'

(function ($) {

    /**
     * diga Access plugin
     * prepares/validates the data coming from the checksum form
     * Connects to the diga api on bobserver
     * Handles the response of the diga api
     * The voucher with the accesscode as a parameter is attached to a link in the survey form
     * @returns {$this}
     */

    $.fn.digaAccess = function () {


        let settings = document.bob.forms.checksum;
        let pre = settings.prefix;
        let g = Globals;

        // Load the bobMsg plugin
        $(settings.classForm).bobMsg(settings);

        /**
         * openMailForm
         * throws event to open bobfram with mail form as its content
         * writes access token/freischaltcode into session storage
         * @param  {} label // label of the code input 
         * @param  {} token // user input (freischaltcode)
         */
        let openMailForm = function (label, token) {
            _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                'src': '#w2-bob-wizard-form2'
            });
            _.setSessionStorage('token', label + ": " + token);
        }

        /**
         * openSurveyForm
         * throws event to open bobframe with survey form as its content
         */
        let openSurveyForm = function () {
            _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                'src': '#w1-bob-wizard-form1'
            });
        }

        let openErrorFrame = function () {
            _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                'src': '#e1-error-frame'
            });
        }

        /**
         * sendData
         * 
         * @param e
         */
        let sendData = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(settings.classForm, g.EVENT_FORM_SUBMIT);

            let formData = _.getFormValidationData(settings.classForm);
            let dataVal = formData.values;
            let dataLabels = formData.labels;

            // validate values - extra method
            let access_code_data = dataVal.access_code.value.replace(/[- ]/g, "").trim();
            let valid = validateFormData(formData, access_code_data);

            if (!valid) {
                // enable button again
                _.submitBtnDisabled(pre, false);

                // event listened on by bob-msg
                _.throwEvent(settings.classForm, g.EVENT_FORM_WARNING);

                return;
            }

            $('document').spinner('show');

            dataVal.name.value = formData.placeholders.name;

            // api2 is used on all projects
            let endpoint = "/broca/v2/voucher";
            let type = 'POST';

            // if the environment is staging we overwrite the endppoint and post type
            // the environment is set rhtough our tamplating
            // can be found in the data-staging attribute of the body element
            // the mockserver is always elevida and response with a 200 and a fake voucher
            if ($('body').data("staging")) {
                endpoint = "https://f02f578c-4cd7-474d-b23b-22938d6727cf.mock.pstmn.io/get";
                type = 'GET';
            }

            $.ajax({
                url: endpoint,
                type: type,
                timeout: 0,
                contentType: "application/x-www-form-urlencoded",
                data: {
                    code: access_code_data
                },
                dataType: "json",
                success: function (response) {
                    // console.log(response);

                    if (response.voucher) {
                        //base64 encode variable
                        //base64 encode variable
                        let params = btoa(JSON.stringify({
                            "diga::accesscode": access_code_data
                        }));

                        // compose redirect location
                        // handle existence/nonexistence of traling slash
                        if (response.endpoint.substr(-1) !== '/') response.endpoint += "/";

                        let loc = `${response.endpoint}de/registration/${response.voucher}`;
                        loc = loc + "?v=" + params;

                        // // myObject, myCategory, myAction
                        _.eTracker2(settings.classForm, 'form', 'diga-access-success');

                        if (_.getSessionStorage(g.STORAGE_SURVEY_STATE)) {
                            // directly into broca
                            window.location.href = loc ?? '/'
                        } else {
                            // show survey form
                            _.setSessionStorage('loc', loc);
                            openSurveyForm();
                        }

                    } else {
                        // opens the mail form and writes the used Freischatcode in its textarea
                        openMailForm(dataLabels.access_code, access_code_data);
                    }

                    _.submitBtnDisabled(pre, false);

                    $('document').spinner('hide');
                },

                error: function (err) {


                    let myAction = `diga_access_failed, ${err.responseText}, status: ${err.status}, timestamp: ${new Date().getTime()}`;
                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', myAction);

                    if (err.responseJSON !== undefined) {

                        switch (err.responseJSON.error) {

                            case ('code taken'):
                                // opens a frame with error message and login link to broca
                                openErrorFrame();
                                break;

                            case ('code expired'):
                                // event listened on bybob-msg
                                _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR_EXPIRED);
                                break;

                            case ('code not found'):
                                // event listened on bybob-msg
                                _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR_NOTFOUND);
                                break;

                            case ('code cancelled'):
                                // opens the mail form and writes the used Freischatcode in its textarea
                                openMailForm(dataLabels.access_code, access_code_data);
                                break;

                            case ('request declined'):
                                // event listened on bybob-msg
                                _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR_EXPIRED);
                                break;

                            case ('invalid code'):
                                // event listened on bybob-msg
                                _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR_CHECKSUM);
                                break;

                            case ('insurer interface down'):
                                // opens the mail form and writes the used Freischatcode in its textarea
                                openMailForm(dataLabels.access_code, access_code_data);
                                break;

                            case ('interface error'):
                                // opens the mail form and writes the used Freischatcode in its textarea
                                openMailForm(dataLabels.access_code, access_code_data);
                                break;

                            default:
                                // opens the mail form and writes the used Freischatcode in its textarea
                                openMailForm(dataLabels.access_code, access_code_data);
                                break;
                        }

                    } else {
                        // opens the mail form and writes the used Freischatcode in its textarea
                        openMailForm(dataLabels.access_code, access_code_data);
                    }
                    _.submitBtnDisabled(pre, false);

                    $('document').spinner('hide');
                }
            });
        };

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @param token
         * @returns {boolean}
         */
        let validateFormData = function (formData, token) {

            let validItem1 = false;
            let $tokenElement = formData.values.access_code.element;

            if (_.validateTokenDiga(token)) {
                $tokenElement.removeClass('is-invalid');
                // formData.values.description.value = "Freischaltcode: " + token;
                validItem1 = true;
            } else {
                $tokenElement.addClass('is-invalid');
            }
            return validItem1;
        };

        $(document).off('.formchecksum');

        $(document).on('submit.formchecksum', 'form.form-checksum', function (e) {
            e.preventDefault();
            sendData(e);
        });

        return this;

    };

}(jQuery));