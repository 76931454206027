import * as Globals from './globals_digas.js'
import ValidationTarget from "./ValidationTarget";

(function ($) {

    /**
     * wizard plugin 3
     * setData form to session storafe
     * @returns {jQuery}
     */
    $.fn.formRezept = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(settings.classForm, g.EVENT_FORM_SUBMIT);

            // get form values
            let formData = _.getFormValidationData(settings.classForm);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable submit button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(settings.classForm, g.EVENT_FORM_WARNING);

                // stop execution
                return;
            }

            sendData();
        };

        let sendData = function () {

            let formData = prepareData();

            console.log(...formData);

            // if the environment is staging we overwrite the mailto email
            // the environment is set through our templating
            // can be found in the data-staging attribute of the body element
            if ($('body').data("staging")) {
                data[("mailto[]")] = g.STAGING_EMAIL;
            }

            $('document').spinner('show');

            $.ajax({
                url: "/prescription",
                type: "POST",
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                cache: false,
                success: function () {

                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#s3-bob-wizard-success3'
                    });

                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', 'rezept-form');
                    _.eTrackerOrder(settings.etrackerId);

                    $('document').spinner('hide');

                    _.submitBtnDisabled(pre, false);

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                },
                error: function (err) {

                    _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR);

                    console.error(err);

                    // enable button again
                    _.submitBtnDisabled(pre, false);
                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validateFormData = function (formData) {

            $('.form-group input', settings.classForm).removeClass('is-invalid');

            let results = [];
            let validationTargets = [
                formData.values.fileupload,
                formData.values.firstname,
                formData.values.lastname,
                formData.values.email,
                formData.values.emailconfirm,
                formData.values.strasse,
                formData.values.housenumber,
                formData.values.plz,
                formData.values.ort,
                formData.values.phone,
                formData.values.kvnr
            ];

            validationTargets.forEach(function (item) {

                let that = new ValidationTarget(item)

                if (that.hasFailedToValidate()) {
                    that.handleInvalid();
                } else {
                    that.handleValid();
                }

                results.push(that.isValid);
            })


            //insurance dropdown validation

            let $dropdown = document.getElementById("fr1-inputInsurance");
            let dropdownValue = $dropdown.options[$dropdown.selectedIndex].value;

            if (dropdownValue !== "") {
                console.log(dropdownValue);
                $dropdown.classList.remove('is-invalid');
            } else {
                $dropdown.classList.add('is-invalid');
            }


            // checkboxes validation

            let $checkbox1 = formData.checkboxes.checkbox1.element;
            let checkbox1Value = formData.checkboxes.checkbox1.status;
            let $checkbox2 = formData.checkboxes.checkbox2.element;
            let checkbox2Value = formData.checkboxes.checkbox2.status;

            if ( checkbox1Value === false) {
                $checkbox1.addClass('is-invalid')
            } else {
                $checkbox1.removeClass('is-invalid')
            }

            if ( checkbox2Value === false) {
                $checkbox2.addClass('is-invalid')
            } else {
                $checkbox2.removeClass('is-invalid')
            }

            if ( checkbox1Value === true && checkbox2Value === true ) {
                //email confirm validation
                if (formData.values.email.value.trim() === formData.values.emailconfirm.value.trim()){
                    formData.values.email.element.removeClass('is-invalid');
                    formData.values.emailconfirm.element.removeClass('is-invalid');

                    return results.every(el => el === true);
                } else {
                    formData.values.email.element.addClass('is-invalid');
                    formData.values.emailconfirm.element.addClass('is-invalid');
                }
            }
        };

        let prepareData = function () {

            let data = _.getFormData(settings.classForm);

            let values = data.data;

            let date = new Date().toISOString().slice(0, 10);

            let fileInput = document.getElementById('formFile');
            let fileName = fileInput.files[0].name;

            let formData = new FormData();

            let data_endpoint = {
                "verificationType": `${values.verificationType ? values.verificationType : 'prescription'}`,
                "name": `${values.firstname}`,
                "surname": `${values.lastname}`,
                "email": `${values.email}`,
                "street": `${values.strasse}`,
                "house": `${values.housenumber}`,
                "city": `${values.ort}`,
                "zip": `${values.plz}`,
                "phone": `${values.phone}`,
                "insuranceCompany": `${values.insuranceName}`,
                "date": `${date}`,
                "product": `${values.product}`,
                "kvnr": `${values.kvnr}`
            }

            formData.append("file", fileInput.files[0], fileName);
            formData.append("data", JSON.stringify(data_endpoint));

            console.log(...formData)

            return formData

        };

        // ********** START: REZEPT STEPS **********
        /** 
            * Validate step data
            * @param validationTargets
            * @returns {boolean}
        */
        let validateStepData = function (validationTargets) {

            $('.form-group input', settings.classForm).removeClass('is-invalid');

            let stepIsValid = true;

            validationTargets.forEach(function (item) {

                let that = new ValidationTarget(item)

                if (that.hasFailedToValidate()) {
                    that.handleInvalid();
                    stepIsValid = false
                } else {
                    that.handleValid();
                }

            })

            return stepIsValid;
        };

        const formFields = ['firstname', 'lastname', 'strasse', 'housenumber', 'plz', 'ort', 'email', 'emailconfirm', 'phone', 'insuranceName', 'kvnr']; 
        formFields.forEach(field => {
            // Update confirm slide when form fields are changed
            if (field !== 'emailconfirm') {
                document.getElementsByName(field)[0].addEventListener('input', (event) => {
                    if (['firstname', 'lastname'].includes(field)) {
                        const firstnameValue = document.querySelector('input[name="firstname"]').value;
                        const lastnameValue = document.querySelector('input[name="lastname"]').value;
                        document.querySelector('#confirm-name').textContent = lastnameValue + ', ' + firstnameValue;
                    } else if (['strasse', 'housenumber'].includes(field)) {
                        const streetValue = document.querySelector('input[name="strasse"]').value;
                        const housenumberValue = document.querySelector('input[name="housenumber"]').value;
                        document.querySelector('#confirm-street').textContent = streetValue + ' ' + housenumberValue;
                    } else if (field === 'insuranceName') {
                        document.querySelector('#confirm-' + field).textContent = event.target.options[event.target.selectedIndex].text;
                    } else {
                        document.querySelector('#confirm-' + field).textContent = event.target.value;
                    };
                });
            }

            // Validate fields after losing focus
            document.getElementsByName(field)[0].addEventListener('blur', () => {
                let formFieldElement = field === 'insuranceName' ? $(`select[name="${field}"] option:selected`) : $(`input[name="${field}"]`);
                let validationTarget = {
                    element: formFieldElement,
                    value: formFieldElement.val()
                }
                validateStepData([validationTarget]);
            });
        });

        // Display file name
        let fileInput = document.getElementById('formFile');
        let fileName = document.getElementById('fileName');
        fileInput.addEventListener('change', function() {
            if (fileInput.files[0]) {
                fileName.innerText = fileInput.files[0].name;
            }
        });

        // Previous slide handling for slides with a top back button
        document.querySelectorAll('.back-btn').forEach(btnBack => {
            btnBack.addEventListener('click', () => {
                const nextStep = document.querySelector('#' + btnBack.getAttribute('data-next-step'));
                document.querySelector('.steps .d-block').classList.replace('d-block', 'd-none');
                nextStep.classList.replace('d-none', 'd-block');
            });
        });

        // Next slide handling for slides with a radio button
        document.querySelectorAll('.steps-radio').forEach(radio => {
            radio.addEventListener('click', () => {
                if (radio.name === 'prescriptionType') {
                    document.getElementById('inputPrescriptionType').setAttribute('value', radio.value);
                } else if (radio.name === 'decision' && radio.value.startsWith('https')) {
                    window.location.href = radio.value;
                }
                const nextStep = document.querySelector('#' + radio.getAttribute('data-next-step'));
                document.querySelector('.steps .d-block').classList.replace('d-block', 'd-none');
                nextStep.classList.replace('d-none', 'd-block');
            });
        });

        // Next slide handling for slides with a button
        document.querySelectorAll('.steps-btn').forEach(btn => {
            btn.addEventListener('click', () => {
                const currentStep = document.querySelector('#' + btn.getAttribute('data-current-step'));
                const nextStep = document.querySelector('#' + btn.getAttribute('data-next-step'));

                // If submit step
                if (currentStep.id === 's2116') {
                    const checkboxConsent = document.querySelector('#checkbox-consent'); 
                    if (checkboxConsent.checked) {
                        checkboxConsent.classList.remove('is-invalid');
                        sendData();
                    } else {
                        checkboxConsent.classList.add('is-invalid');
                    }
                }

                const validationGroups = {
                    's2112': ['fileupload'],
                    's2113': ['insuranceName', 'kvnr', 'email', 'emailconfirm', 'phone'],
                    's2114': ['firstname', 'lastname', 'strasse', 'housenumber', 'plz', 'ort']
                };
                let stepIsValid = true;

                if (currentStep.id in validationGroups && currentStep.id < nextStep.id) {
                    let validationTargets = [];
                    validationGroups[currentStep.id].forEach(formField => {
                        let formFieldElement = formField === 'insuranceName' ? $(`select[name="${formField}"] option:selected`) : $(`input[name="${formField}"]`);
                        let validationTarget = {
                            element: formFieldElement,
                            value: formFieldElement.val()
                        }
                        validationTargets.push(validationTarget);
                    })
                    stepIsValid = validateStepData(validationTargets);
                }
                
                if (stepIsValid && nextStep !== null) { 
                    currentStep.classList.replace('d-block', 'd-none');
                    nextStep.classList.replace('d-none', 'd-block');
                };
            });
        });
        // ********** END: REZEPT STEPS **********

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(document).off('.rezept');

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(document).on('submit.rezept', 'form.form-rezept', function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));