import * as Globals from './globals_digas.js'
import ValidationTarget from './ValidationTarget.js';

(function ($) {

    /**
     * wizard plugin 5
     * setData form to session storafe
     * @returns {jQuery}
     */
    $.fn.bobFormWizard5 = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;
        let event_namespace = '.bobWizard5';

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        $(g.FRAME_FORM_SELECTOR + " .dateTime").removeClass("show");

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);

            // get form values
            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable submit button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

                // stop execution
                return;
            }

            sendData(formData);
        };

        let sendData = function (formData) {

            $('document').spinner('show');

            $.ajax({
                url: "/mail",
                type: "POST",
                data: prepareData(formData),
                cache: false,
                success: function () {

                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#s2-bob-wizard-success2'
                    });

                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', 'kontakt-form-pro');
                    _.eTrackerOrder(settings.etrackerId);

                    $('.form-group-kvnr-token input').val(''); // clear input field of checksum form

                    $('document').spinner('hide');
                    // $('.bob-frame form' + settings.classForm)[0].reset();
                    $('.bob-frame form' + settings.classForm).addClass('hidden').trigger("reset");

                    _.submitBtnDisabled(pre, false);

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                },
                error: function (err) {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);

                    console.error(err);

                    // enable button again
                    _.submitBtnDisabled(pre, false);
                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validateFormData = function (formData) {

            $('.form-group input', g.FRAME_FORM_SELECTOR).removeClass('is-invalid');

            let results = [];
            let validationTargets = [
                formData.values.salutation,
                formData.values.title,
                formData.values.firstname,
                formData.values.secondname,
                formData.values.email,
                formData.values.officename,
                formData.values.officeaddress,
                formData.values.officezip,
                formData.values.officecity
            ];

            if (formData.checkboxes.option1.status) {
                validationTargets.push(formData.values.option1phone)
            }

            validationTargets.forEach(function (item) {

                let that = new ValidationTarget(item)

                if (that.hasFailedToValidate()) {
                    that.handleInvalid();
                } else {
                    that.handleValid();
                }

                results.push(that.isValid);
            })

            return results.every(el => el === true);

        };

        let getSalutationTitle = function (salutation, title) {
            const salutations =  {
                'w': 'Frau',
                'm': 'Herr',
                'd': 'Divers'
            }

            const titles = {
                ''  : '',
                'dr': 'Dr.',
                'profdr': 'Prof. Dr.',
                'profdrdr': 'Prof. Dr. Dr.'
            }

            return `${salutations[salutation]} ${titles[title]}`;
        }

        let prepareData = function (formData) {

            let dataValues = formData.values;
            let values = {};
            let checkboxOptions = formData.checkboxes;
            let checkedBoxes = [];

            for (let key in dataValues) {
                if (dataValues[key].hasOwnProperty('value')) {
                    values[key] = dataValues[key].value;
                }
            }

            for (let key in checkboxOptions) {
                if (checkboxOptions[key].status) {

                    let text = checkboxOptions[key].element.siblings('label').text();

                    if (checkboxOptions[key].element.hasClass('use-date-input')) {

                        let id = key.slice(-1);
                        let date = values[`option${id}-date`];
                        let time = values[`option${id}-time`];
                        text = text.concat(` --> Am: ${date} Um: ${time}`);
                    }

                    if (checkboxOptions[key].element.hasClass('use-phone-input')) {

                        let id = key.slice(-1);
                        let phone = values[`option${id}phone`];
                        text = text.concat(` --> Phone: ${phone}`);
                    }

                    checkedBoxes.push(text);
                }
            }

            let data_endpoint = {
                "email": values.email,
                "name": `${getSalutationTitle(values.salutation, values.title) } ${values.firstname} ${values.secondname}`,
                "description": `${values.officename}\n ${values.officeaddress}\n ${values.officezip} ${values.officecity}\n ${checkedBoxes.join(" \n")} \n ${values.description}\n`,
                "mailto[]": dataValues[("mailto[]")],
                "sender": dataValues.sender,
                "subject": dataValues.subject,
                "submission": dataValues.submission,
            }

            // if the environment is staging we overwrite the mailto email
            // the environment is set rhtough our tamplating
            // can be found in the data-staging attribute of the body element
            if ($('body').data("staging")) {
                data_endpoint[("mailto[]")] = g.STAGING_EMAIL;
            }

            return data_endpoint

        };

        /**
         * Set form values
         */
        let setData = function () {

            // check if data is available in session storage
            let storage = _.getJsonSessionStorage(g.STORAGE_FORM_DATA);
            if (!storage || _.isEmpty(storage)) {
                return;
            }

            // set form values
            if (_.has(storage, 'loc')) {
                $('input[name=description]', g.FRAME_FORM_SELECTOR).val(storage.email);
            }

        };

        let handleCheck = function (e) {
            let $box = $(e.target);
            let id = $box.attr('name').slice(-1);
            let $extraInputs = $(".for-customCheck" + id);

            if ($box.hasClass('use-date-input')) {

                if ($box.is(':checked')) {
                    $extraInputs.addClass("show");
                } else {
                    $extraInputs.removeClass("show");
                }
            }
        }

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);

        /**
         * go back one step in the wizard
         */
        $(g.FRAME_FORM_SELECTOR).on('click' + event_namespace, '.bob-wizard-back', function () {
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FRAME_CONTENT_NEXT, {
                'src': '#w1-bob-wizard-form1'
            });
        });

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        /**
         * fired by bobframe on loading new content
         * next: fill form with data if data is present in session storage
         */
        $(g.FRAME_FORM_SELECTOR).on(g.EVENT_FRAME_CONTENT_CHANGED + event_namespace, function () {
            setData();
        });

        $(g.FRAME_FORM_SELECTOR + ' .checkbox input').on("change" + event_namespace, function (e) {
            handleCheck(e);
        });

        return this;
    };

}(jQuery));
