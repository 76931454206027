

const dropdownElement = document.getElementById("fr1-inputInsurance");

if ($('body.page_1').length > 0 || $('body.page_5').length > 0) {
    window.addEventListener("load", getInsuranceList);
}

function getInsuranceList() {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch("/prescription/insurances", requestOptions)
        .then(response => response.json())
        .then(data => {

            data.sort(function(a, b){
                let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });

            let select = document.getElementById("fr1-inputInsurance");

            data.forEach((item, i) => {
                let option = document.createElement("option");
                option.innerHTML = `${item.name}`;
                option.setAttribute("value", `${item.short}`)
                select.appendChild(option);
            });
        })
        .catch(error => console.log(`Download error: ${error.message}`));

}
