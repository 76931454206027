// get file name for custom file upload
let fileInput = document.getElementById('formFile');
let span = document.getElementById('fileName');

// Fires on file upload
if ($('body.page_5').length > 0) {
    fileInput.addEventListener('change', function (event) {

        // Get file name
        let fileName = fileInput.files[0].name;

        // get file size
        let fileSize = (fileInput.files[0].size / 1024) / 1024;

        // checking file size
        if (fileSize <= 5 && fileSize != 0) {

            // removing error class if any
            span.classList.remove("error-font");

            var customFileUpload = document.getElementById('custom-file-upload');
            var customFileUploadImgPlaceholder = customFileUpload.getElementsByTagName('img')[0];

            customFileUpload.classList.remove('is-invalid');

            // Update file name in span and replace camera icon with check icon
            span.innerText = fileName;
            customFileUploadImgPlaceholder.src = '/images/icon_check.svg';
            customFileUploadImgPlaceholder.classList.add('filter-green');
            
        } else {

            // Displaying error, adding error class
            span.classList.add("error-font");

            // clearing out any previous file name
            span.innerText = "Die Datei ist zu groß! Bitte wählen Sie eine andere Datei.";

            // setting file upload input to null and adding placeholder camera icon
            fileInput.value = "";
            var customFileUploadImgPlaceholder = document.getElementById('custom-file-upload').getElementsByTagName('img')[0];
            customFileUploadImgPlaceholder.src = '/images/form_camera_placeholder.svg';
            customFileUploadImgPlaceholder.classList.remove('filter-green');
        }
    });
}