import * as Globals from './globals_digas.js'

(function ($) {

    /**
     * bobFormContactPatientFlyer plugin
     * setData form to session storage
     * @returns {jQuery}
     */
    $.fn.bobFormContactPatientFlyer = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;
        let event_namespace = '.bobContactPatientFlyer';

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);

            // get form values
            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable submit button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

                // stop execution
                return;
            }

            sendData();
        };

        let sendData = function () {

            let data = prepareData();

            // if the environment is staging we overwrite the mailto email
            // the environment is set through our tamplating
            // can be found in the data-staging attribute of the body element
            if ($('body').data("staging")) {
                data[("mailto[]")] = g.STAGING_EMAIL;
            }

            $('document').spinner('show');

            $.ajax({
                url: "/mail",
                type: "POST",
                data: data,
                cache: false,
                success: function () {

                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#scpf-contact-patient-flyer-success'
                    });

                    // myObject, myCategory, myAction
                    _.eTracker2(settings.classForm, 'form', 'kontakt-form');
                    _.eTrackerOrder(settings.etrackerId);

                    $('.form-group-kvnr-token input').val(''); // clear input field of checksum form

                    $('document').spinner('hide');
                    // $('.bob-frame form' + settings.classForm)[0].reset();
                    $('.bob-frame form' + settings.classForm).addClass('hidden').trigger("reset");

                    _.submitBtnDisabled(pre, false);

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                },
                error: function (err) {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);

                    console.error(err);

                    // enable button again
                    _.submitBtnDisabled(pre, false);
                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validateFormData = function (formData) {

            $('.form-group input', g.FRAME_FORM_SELECTOR).removeClass('is-invalid');

            let validItem1 = false;
            let validItem2 = false;
            let validItem3 = false;
            let validItem4 = false;
            let validItem5 = false;


            // jquery elements
            let $firstname = formData.values.firstname.element;
            let $secondname = formData.values.secondname.element;
            let $address = formData.values.address.element;
            let $postcode = formData.values.postcode.element;
            let $city = formData.values.city.element;
            let $checkbox = formData.checkboxes.checkbox.element;


            // values
            let firstnameValue = formData.values.firstname.value;
            let secondnameValue = formData.values.secondname.value;
            let addressValue = formData.values.address.value;
            let postcodeValue = formData.values.postcode.value;
            let cityValue = formData.values.city.value;
            let checkboxValue = formData.checkboxes.checkbox.status;

            validItem1 = _.checkLength($firstname, firstnameValue, 1, 100);
            validItem2 = _.checkLength($secondname, secondnameValue, 1, 100);
            validItem3 = _.checkLength($address, addressValue, 1, 200);
            validItem5 = _.checkLength($city, cityValue, 1, 100);

            if( _.checkLength($postcode, postcodeValue, 1, 100) && _.validateNumber($postcode, postcodeValue)) {
                validItem4 = true;
            }

            if ( checkboxValue === false) {
                $checkbox.addClass('is-invalid')
            } else {
                $checkbox.removeClass('is-invalid')
            }

            return validItem1 && validItem2 && validItem3 && validItem4 && validItem5 && checkboxValue;

        };

        let prepareData = function () {

            let data = _.getFormData(g.FRAME_FORM_SELECTOR);

            let values = data.data;

            let data_endpoint = {
                "name": `${values.firstname} ${values.secondname}`,
                "description": `${values.address} ${values.postcode} ${values.city}\n\n ${values.description} \n\n ${values.checkbox}`,
                "mailto[]": values[("mailto[]")],
                "email": "nouser@email-provided.de",
                "sender": values.sender,
                "subject": values.subject,
                "submission": values.submission,
            }

            return data_endpoint

        };

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));