import * as Globals from './globals_digas.js'

(function ($) {

    /**
     * wizard plugin 1
     * @returns {jQuery}
     */
    $.fn.bobFormWizard1 = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;
        let event_namespace = '.bobWizard1';
        let showLocationinput = true;

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * Fill if empty
         * @param item
         * @returns {string}
         */
        let fillIfEmpty = function (item) {
            if (item.trim() === '') { // TODO: test this!
                item = 'not provided';
            }
            return item;
        };

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            // disable submit button
            _.submitBtnDisabled(pre, true);

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT, {
                'submitForm': settings.classForm
            });

            // get form values
            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);
            let dataVal = formData.values;
            let dataLabels = formData.labels;


            $('document').spinner('show');

            /**
             * noSelection
             * to avoid sending emails with no information
             * assumes the first dom option elemnt is the default option
             * returns true if the deafault select option is used (user has done nothing)
             */
            let noSelection = function () {
                const defaultVal = $($(g.FRAME_FORM_SELECTOR + ' option')[0]).val();
                return defaultVal === dataVal.marketing.value.trim()
            }

            let noCheckbox = function () {
                return !$(g.FRAME_FORM_SELECTOR + ' .checkbox input').is(':checked')
            }


            // Nothing is filled in or selected -> go into broca project without sending email
            if (dataVal.name.value.trim() === '' && dataVal.location.value.trim() === '' && noSelection() && noCheckbox()) {

                _.eTracker2(settings.classForm, 'umfragen', 'toBroca-directly');
                
                // utelizes the voucher from session storge
                toBroca();

                $('document').spinner('hide');

            } else {

                // sends the survey data to mail ednpoint
                sendData(dataVal, dataLabels);

                // utelizes the voucher from session storge
                toBroca();

            }
        };

        let sendData = function (dataVal, dataLabels) {

            $.ajax({
                url: '/mail',
                type: 'POST',
                data: prepareData(dataVal, dataLabels),
                cache: false,
                success: function () {

                    // clear input field of checksum form
                    $('.form-group-kvnr-token input').val('');

                    // eTracker custom event
                    let pName = _.getProjectName();
                    _.eTracker2(`umfrage-${pName}-ok', 'umfragen', 'umfrage-${pName}-send`);

                    // enable button again
                    _.submitBtnDisabled(pre, false);

                    // hide spinner
                    $('document').spinner('hide');

                    _.removeSessionStorage(g.STORAGE_FORM_DATA);
                    _.setSessionStorage(g.STORAGE_SURVEY_STATE, "true");

                },
                error: function (err) {

                    // enable button again
                    _.submitBtnDisabled(pre, false);

                    console.error(err);

                    // hide spinner
                    $('document').spinner('hide');

                    _.removeSessionStorage(g.STORAGE_FORM_DATA);

                }
            });
        }

        /**
         * Prepare data by modifing the dataVal object for mail mail endpoint
         * @param  {} formData
         */
        let prepareData = function (dataVal, dataLabels) {

            let marketingValue = fillIfEmpty(dataVal.marketing.value);
            let proName = fillIfEmpty(dataVal.name.value);
            let location = dataVal.option1.value;

            if (showLocationinput) {
                location =  fillIfEmpty(dataVal.location.value); 
            }

            let output = {
                "email": 'no-reply@gaia-group.com',
                "name": `Arztabfrage ${window.location.href.includes("zugangverlaengern") ? 'zugangverlaengern' : 'neuer User'}`,
                "description": `${dataLabels.name}: ${proName}\n\nOrt: ${location}\n\n${dataLabels.marketing} ${marketingValue}`,
                "cc[]": dataVal[("cc[]")],
                "mailto[]": dataVal[("mailto[]")],
                "sender": dataVal.sender,
                "subject": dataVal.subject,
                "submission": dataVal.submission,
            };

            // if the environment is staging we overwrite the mailto email
            // the environment is set rhtough our tamplating
            // can be found in the data-staging attribute of the body element
            if ($('body').data("staging")) {
                output[("mailto[]")] = g.STAGING_EMAIL;
                output[("cc[]")] = g.STAGING_EMAIL_CC;
            }

            return output
        };

        /**
         * Redirect to broca
         */
        let toBroca = function () {
            let loc = _.getSessionStorage('loc');
            window.location.href = loc ?? '/';

            // after usage the session storage is removed
            _.removeSessionStorage('loc')
        };

        /**
         * toggle Disabled attribute for Location input
         * also cleares any values that were entered in the input field
         */
        let toggleLocationInput = function () {

            const $input = $(g.FRAME_FORM_SELECTOR + ' .location');
            
            // this is the toggle part
            showLocationinput = !showLocationinput;

            if (showLocationinput === false) {
                $input.prop('disabled', true).val('');
            } else {
                $input.prop('disabled', false);
            }
        }

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        /**
         *  we want to disable the location input when teleclinik is selected
         */
        $(g.FRAME_FORM_SELECTOR + ' .checkbox').on('change' + event_namespace, function () {
            toggleLocationInput();
        });

        return this;
    };

}(jQuery));