// Control button selectors
const playIcon = '<img src="/images/reels_play.svg" alt="play">';
const pauseIcon = '<img src="/images/reels_pause.svg" alt="pause">';
const muteIcon = '<img src="/images/reels_mute.svg" alt="mute">';
const unMuteIcon = '<img src="/images/reels_unmute.svg" alt="unmute">';

$(document).ready(function () {
    function initializePlayer(modal) {
        const carousel = modal.find('.carousel');
        const activeSlide = carousel.find('.carousel-item.active');
        const iframe = activeSlide.find('iframe')[0];

        if (!iframe) return;

        // Stop and unload previous player
        if (modal.data('vimeoPlayer')) {
            console.log('vimeoPlayer')
            const previousPlayer = modal.data('vimeoPlayer');
            previousPlayer.pause().then(() => previousPlayer.unload());
        }

        const player = new Vimeo.Player(iframe);
        modal.data('vimeoPlayer', player);

        // Button references
        const playPauseButton = modal.find('#play-pause-button');
        const playAreaButton = modal.find('.videoPlay');
        const muteUnmuteButton = modal.find('#mute-unmute-button');
        const rewindButton = modal.find('#rewind-button');
        const forwardButton = modal.find('#forward-button');

        let isPlaying = false;
        let isMuted = false;
        let progressTimer = null;

        playPauseButton.html(playIcon);
        muteUnmuteButton.html(muteIcon);

        function trackProgress(duration) {
            const activeSlideIndex = carousel.find('.carousel-item.active').index() - 1;
            const activeIndicator = modal.find(`.carousel-indicators li[data-slide-to="${activeSlideIndex}"]`);

            if (progressTimer) {
                clearInterval(progressTimer);
                progressTimer = null;
            }

            progressTimer = setInterval(() => {
                player.getCurrentTime().then(currentTime => {
                    const progress = (currentTime / duration) * 100;
                    activeIndicator.css({
                        'background': `linear-gradient(to right, rgba(255, 255, 255, 1) ${progress}%, rgba(255, 255, 255, 0.5) ${progress}%)`,
                        'background-clip': 'padding-box',
                    });
                });
            }, 500);
        }

        player.on('loaded', () => {
            player.play().then(() => {
                playPauseButton.html(pauseIcon);
                isPlaying = true;
                player.getDuration().then(duration => trackProgress(duration));
            });
        });

        player.on('ended', () => {
            carousel.carousel('next');
        });

        playPauseButton.off('click').on('click', () => {
            if (isPlaying) {
                player.pause().then(() => {
                    playPauseButton.html(playIcon);
                    isPlaying = false;
                });
            } else {
                player.play().then(() => {
                    playPauseButton.html(pauseIcon);
                    isPlaying = true;
                    player.getDuration().then(duration => trackProgress(duration));
                });
            }
        });

        playAreaButton.off('click').on('click', () => {
            if (isPlaying) {
                player.pause().then(() => {
                    playPauseButton.html(playIcon);
                    isPlaying = false;
                });
            } else {
                player.play().then(() => {
                    playPauseButton.html(pauseIcon);
                    isPlaying = true;
                    player.getDuration().then(duration => trackProgress(duration));
                });
            }
        });

        muteUnmuteButton.off('click').on('click', () => {
            if (isMuted) {
                player.setVolume(1).then(() => {
                    muteUnmuteButton.html(muteIcon);
                    isMuted = false;
                });
            } else {
                player.setVolume(0).then(() => {
                    muteUnmuteButton.html(unMuteIcon);
                    isMuted = true;
                });
            }
        });

        rewindButton.off('click').on('click', () => {
            player.getCurrentTime().then(currentTime => {
                let newTime = Math.max(currentTime - 2, 0);
                player.setCurrentTime(newTime);
            });
        });

        forwardButton.off('click').on('click', () => {
            player.getCurrentTime().then(currentTime => {
                player.getDuration().then(duration => {
                    let newTime = Math.min(currentTime + 2, duration);
                    player.setCurrentTime(newTime);
                    if (duration === newTime) {
                        carousel.carousel('next');
                    }
                });
            });
        });
    }

    $('.modal').on('show.bs.modal', function () {
        const modal = $(this);
        const carousel = modal.find('.carousel');

        carousel.carousel(0);
        carousel.find('.carousel-indicators li').removeClass('watched');

        initializePlayer(modal);
    });

    $('.modal').on('hide.bs.modal', function () {
        const modal = $(this);
        const player = modal.data('vimeoPlayer');
        if (player) {
            player.unload().then(() => {
                modal.removeData('vimeoPlayer');
            });
        }
    });

    $('.modal').on('slid.bs.carousel', function (e) {
        const modal = $(this).closest('.modal');
        const oldPlayer = modal.data('vimeoPlayer');

        if (oldPlayer) {
            oldPlayer.pause().then(() => oldPlayer.unload());
        }

        if (e.direction === 'right') {
            const activeIndicator = modal.find(`.carousel-indicators li[data-slide-to="${e.to}"]`);
            activeIndicator.removeClass('watched');
        }

        if (e.direction === 'left') {
            const activeIndicator = modal.find(`.carousel-indicators li[data-slide-to="${e.from}"]`);
            activeIndicator.addClass('watched');
        }

        initializePlayer(modal);
    });

    if (document.getElementById('scrollable-row')) {
        const row = document.getElementById('scrollable-row');
        const item = row?.querySelector('.col-12');

        if (row && item) {
            const itemWidth = item.offsetWidth - 15;

            document.querySelector('.slide-left').addEventListener('click', function (e) {
                e.preventDefault();
                row.scrollBy({ left: -itemWidth, behavior: 'smooth' });
            });

            document.querySelector('.slide-right').addEventListener('click', function (e) {
                e.preventDefault();
                row.scrollBy({ left: itemWidth, behavior: 'smooth' });
            });
        } else {
            console.error("Element with ID 'scrollable-row' or child items not found.");
        }
    }
});
