export default class URLWatcher {

    constructor() {
        this.watchUrlForDigaCode();
    }

    watchUrlForDigaCode() {
        const currentUrl = new URL(window.location);

        if (!this.hasDigaQueryParam(currentUrl)) {
            return;
        }

        this.setAccessCodeFieldValue(this.decodeDigaParam(this.getDigaQueryParam(currentUrl)));

        setTimeout(() => {
            document.getElementsByClassName('portal')[0].scrollIntoView({ behavior: "smooth" });
        }, 250);

    }

    hasDigaQueryParam(url) {
        return url.searchParams.has('diga');
    }

    getDigaQueryParam(url) {
        return url.searchParams.get('diga');
    }

    decodeDigaParam(digaCode) {
        return atob(digaCode);
    }

    setAccessCodeFieldValue(digaCode) {
        document.getElementById('c2-access_code').value = digaCode;
    }
};