import * as Globals from './globals_digas.js'

(function ($) {

    /**
     * bob message plugin
     * All the functions that are needed for displaying and hiding of the bob messages
     * Handles the focus for accessibility
     * @returns {jQuery}
     */

    $.fn.bobMsg = function (options) {

        if (!_.isObject(options)) {
            console.error('please provide a setting object to bobMsg in main.js');
        }
        let settings = $.extend({}, options);

        // Set bob constants
        let g = Globals;
        let event_namespace_page = '.bobMsg';
        let event_namespace_frame = '.bobMsgFrame';

        let msgFormClass = 'trigger-' + settings.classForm.replace('.', '');

        /**
         * hides the bob message
         * updates the state of the bob message
         * handles focus
         * @param  {} e // the event that triggered bobMsgHide
         */
        let bobMsgHide = function (e) {

            let bobFrameState = _.getJsonSessionStorage(g.STORAGE_FRAME_STATE);
            let bobMsgOpen = _.getJsonSessionStorage(g.STORAGE_MSG_STATE);

            if ($(settings.classMsg).is(':hidden')) {
                return;
            }

            if ((bobMsgOpen && settings.envBobFrame === 'true') ||
                (bobMsgOpen && settings.envBobFrame === 'false' && !bobFrameState.frameOpen)
            ) {

                // on the bobFrameContentChanged event setData() needs to run in wizards forms
                // therefore we don't want to prevent that additional event listener from being called
                // in other situations like when ESC is pressed, we do want to prevent other listeners from being called
                if (!e.type === "bobFrameContentChanged") {
                    e.stopImmediatePropagation();
                }

                $(settings.classMsg).hide();
                $(settings.classMsg + ' .msg-content').hide();

                _.mergeJsonSessionStorage(g.STORAGE_MSG_STATE, {
                    'msgOpen': false
                });

                removeMsgClassesOnClose($(settings.classMsg));

                // If closing the msg is not triggered by submitting the form
                // AND If closing the msg is not triggered by the user typing into an input field
                // but clicking the close button or pressing esc
                if (e?.trigger !== 'submit' && e.type !== 'input') {
                    if (settings.envBobFrame === 'false') {
                        handleFocusPage();
                    } else {
                        handleFocusFrame();
                    }
                }
            }
        };

        /**
         * hides the bob message (caused by closing the frame)
         * updates the state of the bob message
         * handles focus
         * @param  {} e // the event that triggered bobMsgHide
         */
        let bobMsgHideConditional = function () {

            let activeMsg = $(g.MSG_ACTIVE_SELECTOR);

            if (activeMsg && !activeMsg.hasClass('success')) {

                activeMsg.hide();
                activeMsg.children('.msg-content').hide();

                _.mergeJsonSessionStorage(g.STORAGE_MSG_STATE, {
                    'msgOpen': false
                });
                
                removeMsgClassesOnClose(activeMsg);
            }

            if (settings.envBobFrame === 'false') {
                handleFocusPage();
            } else {
                handleFocusFrame();
            }

        };

        /**
         * calls setFocusToFormElement
         */
        let handleFocusPage = function () {
            setFocusToFormElement(settings.classForm);
        };

        /**
         * handles the focus of forms in the bob-frame context
         * sets focus on an input or on the element that opned the frame
         */
        let handleFocusFrame = function () {

            let bobFrameState = _.getJsonSessionStorage(g.STORAGE_FRAME_STATE);

            if (_.has(bobFrameState, 'frameOpen')) {

                if (bobFrameState.frameOpen) {
                    setFocusToFormElement(g.FRAME_FORM_SELECTOR);
                } else {
                    _.setFocusToTriggerItem();
                }
            }
        };

        /**
         * cleans up classes that were set on bob-msgs
         * @param  {} msgItem //jQuery object that contains a bob-msg element
         */
        let removeMsgClassesOnClose = function (msgItem) {

            msgItem.removeClass(function (index, className) {
                return (className.match(/(^|\s)trigger-\S+/g) || []).join(' ');
            });

            msgItem.removeClass('active sucess warning error');
        };

        /**
         * sets focus to the first empty input in a form
         * @param {string} form //jQuery selector of a form element
         */
        let setFocusToFormElement = function (form) {

            let inputs = $('.form-group input.is-invalid', form);
            let focusTarget = inputs[0] ?? $('.submit', form);

            focusTarget.focus();
        };

        /**
         * shows a bob msg
         * handles state of the bobMsg
         * handles focus in the openned message
         * @param {string} msg //jQuery selector of a message type (warning, success, error)
         */
        let bobMsgShow = function (msg) {

            let msgToShow = $(settings.classMsg + ' .msg-' + msg);

            msgToShow.show();
            $(settings.classMsg).addClass('active ' + msg + ' ' + msgFormClass);

            // this sets rhe aria-label of msg-close depending on the message shown
            let ariaLabel = $('.msg-content-txt', msgToShow).html();
            $('.msg-close').attr('aria-label', ariaLabel);

            $(settings.classMsg).fadeIn(200);

            // when triggered, this event will focus the X symbol to read the dynamic aria-label
            $('.msg-close', settings.classMsg).focus();

            _.mergeJsonSessionStorage(g.STORAGE_MSG_STATE, {
                'msgOpen': true
            });
        };

        /**
         * EVENT LISTENERS
         */
         let bobEventNamespace = event_namespace_page;
         if (settings.envBobFrame === 'true') {
             bobEventNamespace = event_namespace_frame;
         }
 
         // Reset listeners
         $(document).off(bobEventNamespace);

        /**
         * on clicking on the message itself
         * we want the message to close
         */
        $(document).on('click' + bobEventNamespace, '.' + msgFormClass, function (e) {
            bobMsgHide(e);
        });

        /**
         * when the form submitted the message should close
         * this events are triggered in the form scripts
         */
        $(settings.classForm).off(g.EVENT_FORM_SUBMIT);
        $(settings.classForm).on(g.EVENT_FORM_SUBMIT, function (e) {
            bobMsgHide(e);
        });

        /**
         * when the form content is changed the message should close
         * this events are triggered in the form scripts
         */
        $(settings.classForm).off(g.EVENT_FRAME_CONTENT_CHANGED);
        $(settings.classForm).on(g.EVENT_FRAME_CONTENT_CHANGED, function (e) {
            bobMsgHide(e);
        });

        /**
         * this hides the bobsmsg when the user starts typing again
         */
         $(settings.classForm).on('input' + bobEventNamespace, function (e) {
            const debouncedHide = _.debounce(bobMsgHide, 400);
            $(e.target).removeClass('is-invalid'); 
            debouncedHide(e);
        });

        /**
         * failed validation makes a warning message popup
         * this event is triggerd in the the form script
         */
         $(settings.classForm).off(g.EVENT_FORM_WARNING);
         $(settings.classForm).on(g.EVENT_FORM_WARNING, function () {
             bobMsgShow('warning');
         });

        /**
         * failed request makes a error message popup
         * this event is triggerd in the the form script
         */
        $(settings.classForm).off(g.EVENT_FORM_ERROR);
        $(settings.classForm).on(g.EVENT_FORM_ERROR, function () {
            bobMsgShow('error');
        });

        /**
         * failed request makes a error message popup
         * this event is triggerd in the the form script
         */
         $(settings.classForm).off(g.EVENT_FORM_ERROR_CHECKSUM);
         $(settings.classForm).on(g.EVENT_FORM_ERROR_CHECKSUM, function () {
             bobMsgShow('error-checksum');
         });

        /**
         * failed request makes a error message popup
         * this event is triggerd in the the form script
         */
         $(settings.classForm).off(g.EVENT_FORM_ERROR_DECLINED);
         $(settings.classForm).on(g.EVENT_FORM_ERROR_DECLINED, function () {
             bobMsgShow('error-declined');
         });

        /**
         * expired freischaltcodes makes an error message popup
         * this event is triggerd in the the form script
         */
         if (g.EVENT_FORM_ERROR_EXPIRED !== undefined) {
            $(settings.classForm).off(g.EVENT_FORM_ERROR_EXPIRED);
            $(settings.classForm).on(g.EVENT_FORM_ERROR_EXPIRED, function () {
                bobMsgShow('error-expired');
            });
        }

        /**
         * notfound freischaltcodes makes an error message popup
         * this event is triggerd in the the form script
         */
         if (g.EVENT_FORM_ERROR_NOTFOUND !== undefined) {
            $(settings.classForm).off(g.EVENT_FORM_ERROR_NOTFOUND);
            $(settings.classForm).on(g.EVENT_FORM_ERROR_NOTFOUND, function () {
                bobMsgShow('error-notfound');
            });
        }

        /**
         * insurer error makes an error message popup
         * this event is triggerd in the the form script
         */
         if (g.EVENT_FORM_ERROR_INSURER !== undefined) {
            $(settings.classForm).off(g.EVENT_FORM_ERROR_INSURER);
            $(settings.classForm).on(g.EVENT_FORM_ERROR_INSURER, function () {
                bobMsgShow('error-insurer');
            });
        }

        /**
         * successfull request makes a success message popup
         * this event is triggerd in the the form script
         */
        $(settings.classForm).off(g.EVENT_FORM_SUCCESS);
        $(settings.classForm).on(g.EVENT_FORM_SUCCESS, function () {
            bobMsgShow('success');
        });

        /**
         * on closing bobframe check if its not a success message and close message
         * this event is triggerd in the bobframe.js
         */
        $(document).on(g.EVENT_FRAME_HIDE + bobEventNamespace, function (e) {
            bobMsgHideConditional(e);
        });

        /**
         * Close message on esc
         */
        $(document).on('keydown' + bobEventNamespace, function (e) {
            e = e || window.event;
            if (e.keyCode === 27) {
                bobMsgHide(e);
            }
        });


        return this;

    };

}(jQuery));